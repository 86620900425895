import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'react-intl';
import Img from 'gatsby-image';
import video from '../video/video.mp4';

const getImages = graphql`
  query {
    looped: file(relativePath: { eq: "object1.png" }) {
      childImageSharp {
        fixed(width: 576, height: 566) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    },
    sliderImage1: file(relativePath: { eq: "consulting-europe.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    sliderImage2: file(relativePath: { eq: "coding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

function HeaderSection({ intl}) {
  const { looped, sliderImage1, sliderImage2 } = useStaticQuery(getImages)
  return (
    <section id="home" className="cursor-light p-0">
      <h2 className="d-none">hidden</h2>
      <div
        id="rev_slider_19_1_wrapper"
        className="rev_slider_wrapper fullscreen-container"
        data-alias="wexim_slider_01"
        data-source="gallery"
        style={{ background: 'transparent', padding: '0px' }}
      >
        <div
          id="rev_slider_19_1"
          className="rev_slider fullscreenbanner"
          style={{ display: 'none' }}
          data-version="5.4.8.1"
        >
          <ul>
            <li
              data-index="rs-1"
              data-transition="crossfade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src={sliderImage1.childImageSharp.fluid.src}
                alt=""
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />
              <div className="gradient-bg1 bg-overlay opacity-9 z-index-1" />
              <div
                className="tp-caption   tp-resizeme"
                id="slide-91-layer-1"
                data-x="['left','left','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','middle','middle']"
                data-voffset="['309','262','-130','-130']"
                data-fontsize="['16','16','16','16']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":200,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 6,
                  whiteSpace: 'nowrap',
                  fontSize: '40px',
                  lineHeight: '50px',
                  fontWeight: '500',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'
                }}
              >
                {intl.formatMessage({ id: 'header.b2a_solutions_welcome' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                id="slide-91-layer-2"
                data-x="['left','left','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['357','309','383','255']"
                data-fontsize="['40','40','40','35']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":720,"split":"chars","splitdelay":0.1,"speed":900,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 7,
                  whiteSpace: 'nowrap',
                  fontSize: '40px',
                  lineHeight: '50px',
                  fontWeight: '200',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'
                }}

              >
                {intl.formatMessage({ id: 'header.it_consulting_company' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                id="slide-91-layer-3"
                data-x="['left','left','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','-15','-30']"
                data-fontsize="['40','40','40','35']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":1290,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 7,
                  whiteSpace: 'nowrap',
                  fontSize: '40px',
                  lineHeight: '50px',
                  fontWeight: '200',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'
                }}
              >
                {intl.formatMessage({ id: 'header.located_in_brussels' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                id="slide-91-layer-4"
                data-x="['left','left','center','center']"
                data-hoffset="['0','50','1','-1']"
                data-y="['top','top','middle','middle']"
                data-voffset="['484','447','60','45']"
                data-fontsize="['16','16','16','16']"
                data-lineheight="['22','22','22','18']"
                data-width="['601','530','700','450']"
                data-height="none"
                data-whitespace="normal"
                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":1680,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 8,
                  minWidth: '601px',
                  maxWidth: '601px',
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: '300',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Roboto'
                }}
              >
                <p className="text-white">
                  {intl.formatMessage({ id: 'header.your_one_click_away' })}
                </p>
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['left','left','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','middle','middle']"
                data-voffset="['586','560','164','150']"
                data-width="['601','530','601','450']"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":2150,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 9,
                  letterSpacing: '.5px'
                }}
              >
                <a href="#about" className="btn btn-large btn-rounded btn-white scroll">{intl.formatMessage({ id: 'header.learn_more' })}</a>
              </div>
              <div
                className="tp-caption d-none d-lg-block tp-resizeme rs-parallaxlevel-1"
                id="slide-91-layer-7"
                data-x="['right','right','right','right']"
                data-hoffset="['0','-50','-1200','-1200']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','-9','-27']"
                data-width="none"
                data-height="none"
                data-whitespace="normal"

                data-type="image"
                data-responsive_offset="on"

                data-frames='[{"delay":1810,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 10 }}
              >
                <div
                  className="rs-looped rs-wave"
                  data-speed="10"
                  data-angle="0"
                  data-radius="5px"
                  data-origin="50% 50%"
                >
                  <Img fixed={looped.childImageSharp.fixed} alt="" data-ww="['576px','530px','462px','462px']" data-hh="['566px','530px','454px','454px']" data-no-retina />
                </div>
              </div>
              <div
                className="tp-caption d-none d-lg-block tp-resizeme rs-parallaxlevel-2"
                id="slide-91-layer-8"
                data-x="['right','right','right','right']"
                data-hoffset="['10','-60','-1200','-1200']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['70','70','20','2']"
                data-width="none"
                data-height="none"
                data-whitespace="normal"

                data-type="image"
                data-responsive_offset="on"

                data-frames='[{"delay":2330,"speed":900,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 11 }}
              >

                <div
                  className="rs-looped rs-wave"
                  data-speed="20"
                  data-angle="0"
                  data-radius="8px"
                  data-origin="50% 50%"
                >
                  <svg width="500" height="495">
                    <g>
                      <clipPath id="clipPolygon">
                        <polygon
                          points="186 13,164 17,150 19,118 27,92 36,78 44,59 57,39 78,26 98,18 118,14 133,12 143,9 159,9 174,12 191,15 207,18 219,22 231,28 244,38 267,50 290,65 313,82 337,97 356,109 369,126 385,138 397,156 411,176 422,192 427,211 431,226 433,244 431,258 428,272 423,286 417,298 409,315 397,336 374,349 357,363 335,380 306,394 277,405 250,414 224,423 196,427 178,429 162,429 140,426 122,420 100,413 86,404 72,391 57,373 42,355 32,333 24,307 18,278 13,249 11,207 11,182 14">
                        </polygon>
                      </clipPath>
                    </g>
                    <image
                      clipPath="url(#clipPolygon)"
                      height="100%"
                      width="100%"
                      href="/images/try.jpg"
                    />
                  </svg>
                </div>

              </div>
            </li>

            <li
              data-index="rs-2"
              data-transition="crossfade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""

              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src={sliderImage2.childImageSharp.fluid.src}
                alt=""
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />
              <div className="gradient-bg1 bg-overlay opacity-9 z-index-1" />

              <div
                className="tp-caption   tp-resizeme"
                id="slide-92-layer-1"
                data-x="['center','center','center','center']"
                data-hoffset="['-5','-5','-5','-5']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-77','-77','-77','-85']"
                data-fontsize="['30','30','25','20']"
                data-fontweight="['600','600','600','500']"
                data-letterspacing="['4','0','0','2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":240,"speed":900,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 5,
                  whiteSpace: 'nowrap',
                  fontSize: '30px',
                  lineHeight: '35px',
                  fontWeight: '600',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'

                }}
              >
                {intl.formatMessage({ id: 'header.the_best_solution' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','3']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','7','7','-32']"
                data-fontsize="['80','70','60','40']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":950,"speed":2000,"sfxcolor":"#ffffff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 6,
                  whiteSpace: 'nowrap',
                  fontSize: '30px',
                  lineHeight: '80px',
                  fontWeight: '200',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Roboto'
                }}
              >
                {intl.formatMessage({ id: 'header.b2asolutions' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['center','center','center','center']"
                data-hoffset="['1','1','0','4']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['83','83','83','18']"
                data-fontsize="['18','18','16','14']"
                data-width="500"
                data-height="none"
                data-whitespace="normal"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":1670,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 7,
                  minWidth: '500px',
                  maxWidth: '500px',
                  whiteSpace: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  fontWeight: '300',
                  color: '#ffffff',
                  letterSpacing: '1px',
                  fontFamily: 'Roboto'
                }}
              >
                <p className="text-white">
                  {intl.formatMessage({ id: 'header.we_design_scalable' })}
                </p>
              </div>
              <div
                className="tp-caption   tp-resizeme"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['170','170','170','95']"
                data-fontsize="['20','20','16','16']"
                data-width="['601','530','601','450']"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":2300,"speed":900,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 9,
                  letterSpacing: '.9px'
                }}
              >
                <a
                  className="btn btn-large btn-rounded btn-transparent-white scroll"
                  href="#about"
                >
                  {intl.formatMessage({ id: 'header.learn_more' })}
                </a>
              </div>

            </li>

            <li
              data-index="rs-3"
              data-transition="crossfade"
              data-slotamount="default"
              data-hideafterloop="0"

              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <div className="gradient-bg1 bg-overlay opacity-9 z-index-1" />

              <div
                className="rs-background-video-layer"
                data-forcerewind="on"
                data-volume="mute"
                data-videowidth="100%"
                data-videoheight="100vh"
                data-videomp4={video}
                data-videopreload="auto"
                data-videoloop="loopandnoslidestop"
                data-forcecover="1"
                data-aspectratio="16:9"
                data-autoplay="true"
                data-autoplayonlyfirsttime="false"
              />

              <div
                className="tp-caption   tp-resizeme"
                data-x="['right','right','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','middle','middle']"
                data-voffset="['309','262','-130','-130']"
                data-fontsize="['16','16','16','16']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":200,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['right','right','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 5,
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: '300',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Roboto'
                }}
              >
                <p className="text-white">
                  {intl.formatMessage({ id: 'header.dreams_reality' })}
                </p>
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['right','right','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['357','309','383','255']"
                data-fontsize="['40','40','40','35']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":720,"split":"chars","splitdelay":0.1,"speed":900,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['right','right','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 6,
                  whiteSpace: 'nowrap',
                  fontSize: '40px',
                  lineHeight: '50px',
                  fontWeight: '500',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'
                }}
              >
                {intl.formatMessage({ id: 'header.team_experts' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['right','right','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','-15','-30']"
                data-fontsize="['40','40','40','35']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":1290,"speed":900,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['right','right','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 7,
                  whiteSpace: 'nowrap',
                  fontSize: '40px',
                  lineHeight: '50px',
                  fontWeight: '200',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins'
                }}
              >
                {intl.formatMessage({ id: 'header.located_brussels' })}
              </div>

              <div
                className="tp-caption   tp-resizeme"
                data-x="['right','right','center','center']"
                data-hoffset="['0','50','1','-1']"
                data-y="['top','top','middle','middle']"
                data-voffset="['484','447','60','45']"
                data-fontsize="['16','16','16','16']"
                data-lineheight="['22','22','22','18']"
                data-width="['601','530','700','450']"
                data-height="none"
                data-whitespace="normal"

                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":1680,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['right','right','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 8,
                  minWidth: '601px',
                  maxWidth: '601px',
                  whiteSpace: 'normal',
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: '300',
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Roboto'
                }}
              >
                <p className="text-white">
                  {intl.formatMessage({ id: 'header.highly_skilled' })}
                </p>
              </div>
              <div
                className="tp-caption   tp-resizeme"
                data-x="['right','right','center','center']"
                data-hoffset="['0','50','0','0']"
                data-y="['top','top','middle','middle']"
                data-voffset="['586','560','164','145']"
                data-width="['601','530','601','450']"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"

                data-frames='[{"delay":2150,"speed":900,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":900,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['right','right','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"

                style={{
                  zIndex: 9,
                  letterSpacing: '.5px'
                }}
              >
                <a
                  className="btn btn-large btn-rounded btn-white scroll"
                  href="#about"
                >
                  {intl.formatMessage({ id: 'header.learn_more' })}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="slider-social">
        <ul className="list-unstyled">
          <li className="animated-wrap">
            <a className="animated-element" href="https://www.facebook.com/B2Asolutions/">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li className="animated-wrap">
            <a className="animated-element" href="https://www.instagram.com/b2a_solutions/">
              <i className="fa fa-instagram" />
            </a>
          </li>
          <li className="animated-wrap">
            <a className="animated-element" href="https://twitter.com/B2A_solutions">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li className="animated-wrap">
            <a className="animated-element" href="https://www.linkedin.com/company/b2asolutions/">
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
          </li>
        </ul>
      </div>
      <a href="#about" className="scroll-down link scroll">
        Scroll Down <i className="fa fa-long-arrow-down" />
      </a>
    </section>
  )
}

export default injectIntl(HeaderSection);
