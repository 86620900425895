import React, { useContext } from 'react';
import { PageContext } from '../layout';
import Img from 'gatsby-image';

export default function JobDetails({ job }) {
  const pageContext = useContext(PageContext);
  const { locale } = pageContext.page;
  return (
    <div className="team-box item">
      <div className="team-image">
        <Img fluid={job.jobImage.fluid} />
      </div>
      <div className="team-text">
        <h5>{job[`jobTitle_${locale}`]}</h5>
        <span className="alt-font">{job.createdAt}</span>
      </div>
      <ul className="team-progress text-left">
        {job.jobSkills.skills.map(({skill, value}) => (
          <li key={skill} className="progress-item">
            <h6>{skill}<span className="float-right"><b className="count">{value}</b>%</span></h6>
            <div className="progress">
              <span className="progress-bar" role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
