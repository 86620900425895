import React from 'react'
import { FormattedMessage } from 'react-intl';
export function initMap() {
  // Basic options for a simple Google Map
  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
  const mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 12,
    zoomControl: true,
    scaleControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    // The latitude and longitude to center the map (always required)
    center: new window.google.maps.LatLng(50.833324, 4.388553), // New York

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c6c6c6"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}]
  };

  // Get the HTML DOM element that will contain your map
  // We are using a div with id="map" seen below in the <body>
  const mapElement = document.getElementById('map');

  // Create the Google Map using our element and options defined above
  const map = new window.google.maps.Map(mapElement, mapOptions);

  // Let's also add a marker while we're at it
  new window.google.maps.Marker({
    position: new window.google.maps.LatLng(50.833324, 4.388553),
    map: map,
    title: 'B2A solutions'
  });
}

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
  if (!re.test(String(email).toLowerCase())) {
    return <FormattedMessage id="contact.email.invalidEmail" />
  }
}

const required = (value, key) => {
  if (!value || value === '' || value.trim() === '') {
    let reqId = `contact.${key}.errorMessage`
    return <FormattedMessage id={reqId} />
  }
}

const validationConfig = [{ key: 'first_name' }, { key: 'last_name' }, { key: 'message' }, { key: 'email' }]

export function validateForm(formValues, locale) {
  let err = {}
  validationConfig.forEach(item => {
    const requiredValidation = required(formValues[item.key], item.key, locale)
    err[item.key] = requiredValidation ? [requiredValidation] : null
  })
  const emailValidation = validateEmail(formValues.email, locale)
  if (emailValidation) {
    if (!err.email) {
      err.email = [emailValidation]
    }
  }
  return err
}
