import React from 'react';
import { injectIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import AppSection from './AppSection';

const getImages = graphql`
  query {
    lapTopSlideImage2: file(relativePath: { eq: "illustration-coding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

function AboutSection({ intl }) {
  const data = useStaticQuery(getImages);
  return (
    <section id="about">
      <div className="container">
        <div className="row align-items-center wow fadeIn">
          <div className="col-md-6">
            <div className="title">
              <h6 className="third-color mb-3">{intl.formatMessage({ id: 'about.about_us' })}</h6>
              <h2>{intl.formatMessage({ id: 'about.it_consulting_company' })}</h2>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <p>
              {intl.formatMessage({ id: 'about.company_explanation' })}
            </p>
          </div>
        </div>

        <div className="row mt-lg-5">
          <div className="col-md-4 wow fadeInLeft">
            <div className="feature-box">
              <span className="item-number gradient-text1">
                  01.
              </span>
              <h6 className="mb-4">{intl.formatMessage({ id: 'about.web_mobile_apps' })}</h6>
              <p>
                {intl.formatMessage({ id: 'about.web_mobile_apps_explanation' })}
              </p>
            </div>
          </div>
          <div className="col-md-4 wow fadeInUp">
            <div className="feature-box">
              <span className="item-number gradient-text1">
                  02.
              </span>
              <h6 className="mb-4">{intl.formatMessage({ id: 'about.digital_strategy' })}</h6>
              <p>
                {intl.formatMessage({ id: 'about.digital_strategy_explanation' })}
              </p>
            </div>
          </div>
          <div className="col-md-4 wow fadeInRight">
            <div className="feature-box">
              <span className="item-number gradient-text1">
                  03.
              </span>
              <h6 className="mb-4">{intl.formatMessage({ id: 'about.consulting_trainings' })}</h6>
              <p>
                {intl.formatMessage({ id: 'about.consulting_trainings_explanation' })}
              </p>
            </div>
          </div>
        </div>

        <div className="row laptop text-center">
          <div className="col-md-12">
            <div className="laptop-img wow fadeInUp">
              <img src="/images/laptop-img.png" alt="Laptop screen" />
              <div id="laptop-slide">
                <div className="item">
                  <Img fluid={data.lapTopSlideImage2.childImageSharp.fluid} alt="coding skills" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <AppSection/>
    </section>
  )
}

export default injectIntl(AboutSection);
