import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'react-intl';
import JobDetails from '../components/JobDetails';

const getJobs = graphql`
  query MyQuery {
  allContentfulJob {
      nodes {
        id
        createdAt(formatString: "DD/MM/YYYY")
        jobTitle_en
        jobTitle_fr
        jobDescription_en {
          json
        }
        jobDescription_fr {
          json
        }
        jobSkills { 
         skills {
            skill
            value
          }
        },
        jobImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

function JobsSection({ intl }) {
  const data = useStaticQuery(getJobs);
  return (
    <section id="jobs" className="bg-light">
      <div className="container">
        <div className="row wow fadeIn">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h2 className="gradient-text1 mb-3">{intl.formatMessage({ id: 'jobs.jobs' })}</h2>
              <p>
                {intl.formatMessage({ id: 'jobs.jobs_explanation' })}
              </p>
            </div>
          </div>
        </div>
        <div className="row align-content-center justify-content-center">
          <div className="col-sm-12">
            <div id="team-slider" className="owl-carousel owl-theme wow fadeInUp" style={{display: 'flex', justifyContent: 'center'}}>
              {data.allContentfulJob.nodes.map(job => <JobDetails key={job.id} job={job} />)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(JobsSection)
