import React from 'react';
import { injectIntl } from 'react-intl';

function AppSection({ intl }) {
  return (
    <div className="container" style={{ marginTop: '40px' }}>
      <div className="row">
        <div className="col-md-12 text-center wow fadeIn">
          <div className="title d-inline-block">
            <h6 className="mb-3">{intl.formatMessage({ id: 'about.reliable_app' })}</h6>
            <h2 className="gradient-text1 mb-3">{intl.formatMessage({ id: 'about.app_development' })}</h2>
            <p>
              {intl.formatMessage({ id: 'about.app_development_explanation' })}
            </p>
          </div>
        </div>
      </div>

      <div className="row align-items-center text-center">

        <div className="col-md-4 wow fadeInLeft">
          <div className="app-feature">
            <i className="fa fa-fast-forward gradient-text1" aria-hidden="true" />
            <h4 className="mb-3">{intl.formatMessage({ id: 'about.fast' })}</h4>
            <p>
              {intl.formatMessage({ id: 'about.fast_explanation' })}
            </p>
          </div>
          <div className="app-feature">
            <i className="fa fa-edit gradient-text1" aria-hidden="true" />
            <h4 className="mb-3">{intl.formatMessage({ id: 'about.customized' })}</h4>
            <p>
              {intl.formatMessage({ id: 'about.customized_explanation' })}
            </p>
          </div>
        </div>

        <div className="col-md-4 wow fadeInUp">
          <div className="app-image">
            <img src="/images/iphone-img.png" alt="iphone screen" />
            <div id="app-slider" className="owl-carousel owl-theme">
              <div className="item">
                <img src="/images/app-example.png" alt="login screen" />
              </div>
              <div className="item">
                <img src="/images/app-example-2.png" alt="register screen" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 wow fadeInRight">
          <div className="app-feature">
            <i className="fa fa-code gradient-text1" aria-hidden="true" />
            <h4 className="mb-3">{intl.formatMessage({ id: 'about.clean_code' })}</h4>
            <p>
              {intl.formatMessage({ id: 'about.clean_code_explanation' })}
            </p>
          </div>
          <div className="app-feature">
            <i className="fa fa-mobile gradient-text1" aria-hidden="true" />
            <h4 className="mb-3">{intl.formatMessage({ id: 'about.responsive' })}</h4>
            <p>
              {intl.formatMessage({ id: 'about.responsive_explanation' })}
            </p>
          </div>
        </div>

      </div>

    </div>
  )
}


export default injectIntl(AppSection);
