import React from 'react'
import { injectIntl } from 'react-intl';
import ContactForm from '../components/ContactForm';

function ContactSection({ intl }) {
  return (
    <section id="contact" style={{ paddingTop: '0' }}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 p-0">
            <div className="address-box title mb-0 bg-img4">
              <div className="bg-overlay gradient-bg1 opacity-8" />
              <div className="address-text text-center text-white position-relative wow fadeInUp" style={{ height: '680px' }}>
                <p className="mb-3 text-white">{intl.formatMessage({ id: 'contact.city' })} </p>
                <p className="mb-3 text-white">
                  {intl.formatMessage({ id: 'contact.phone' })} : 0032 (0)4 71 76 03 45<br />
                </p>
                <p className="mb-3 text-white">
                  {intl.formatMessage({ id: 'contact.email' })} : director@b2asolutions.com<br />
                </p>
                <div className="address-social">
                  <ul className="list-unstyled">
                    <li>
                      <a className="facebook-text-hvr" href="https://www.facebook.com/B2Asolutions/">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a className="twitter-text-hvr" href="https://twitter.com/B2A_solutions">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin-text-hvr" href="https://www.linkedin.com/company/b2asolutions/">
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a className="instagram-text-hvr" href="https://www.instagram.com/b2a_solutions/">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div id="map" className="half-map bg-img-map" />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: '40px' }}>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h2>{intl.formatMessage({ id: 'contact.keep_in_touch' })}</h2>
            </div>
          </div>
        </div>

        <ContactForm />
      </div>
    </section>
  )
}

export default injectIntl(ContactSection)
