import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl'
import { validateForm } from '../utils'
import Recaptcha from 'react-google-recaptcha'
import { PageContext } from '../layout';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const displayErrorMessage = (errors, label, meta, submitted) =>
  errors[label] &&
  (meta[label] || submitted) && (
    <span className="error-message">
      {errors[label].map((err, index) => (
        <span key={index} style={{ display: 'block' }}>{err}</span>
      ))}
    </span>
  );

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
};

const initialForm = {
  first_name: '',
  last_name: '',
  email: '',
  message: ''
};

const ContactForm = ({ intl }) => {
  const pageContext = useContext(PageContext);
  const { locale } = pageContext.page;
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [form, setForm] = useState(initialForm)
  const [recaptcha, setRecaptcha] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [meta, setMeta] = useState({})
  const errors = validateForm(form)
  const handleChangeField = e => {
    setMeta({
      ...meta,
      [e.target.name]: 'touched'
    });
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };
  const handleChangeRecaptcha = value => {
    setRecaptcha(value)
  };
  const handleSubmit = e => {
    setSubmitted(true);
    if (
      Object.keys(errors).filter(key => errors[key]).length === 0 &&
      recaptcha
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          'g-recaptcha-response': recaptcha,
          ...form
        })
      })
        .then(() => setSuccess(true))
        .catch(() => setError(true))
    }

    e.preventDefault();
  };
  return (
    <form
      data-netlify-recaptcha="true"
      name="contact"
      method="POST"
      className="contact-form"
      data-netlify="true"
    >
      <div className="row">
        {error && (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <h3>{intl.formatMessage({
              id: 'contact.send_error'
            })}}</h3>
          </div>
        )}
        {success ? (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <h2>
              {intl.formatMessage({
                id: 'contact.send_success'
              })}
            </h2>
          </div>
        ) : (
          <>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder={intl.formatMessage({ id: 'contact.first_name' })}
                  onChange={handleChangeField}
                />
                {displayErrorMessage(errors, 'first_name', meta, submitted)}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  placeholder={intl.formatMessage({ id: 'contact.last_name' })}
                  onChange={handleChangeField}
                />
                {displayErrorMessage(errors, 'last_name', meta, submitted)}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder={intl.formatMessage({ id: 'contact.email' })}
                  onChange={handleChangeField}
                />
                {displayErrorMessage(errors, 'email', meta, submitted)}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  onChange={handleChangeField}
                  placeholder={intl.formatMessage({ id: 'contact.message' })}
                />
                {displayErrorMessage(errors, 'message', meta, submitted)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Recaptcha
                hl={locale}
                sitekey={RECAPTCHA_KEY}
                onChange={handleChangeRecaptcha}
              />
            </div>
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-large btn-gradient btn-rounded mt-4"
                id="submit_btn"
                disabled={recaptcha === null}
                onClick={handleSubmit}
              >
                <i className={submitted && !error && !success ? 'fa fa-spinner fa-spin mr-2' : 'fa fa-spinner fa-spin mr-2 d-none'} aria-hidden="true" />
                <span>{intl.formatMessage({ id: 'contact.contact_now' })}</span>
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default injectIntl(ContactForm);
