import React, { useEffect } from 'react';

import withLayout from '../layout';
import HeaderSection from '../sections/HeaderSection';
import AboutSection  from '../sections/AboutSection';
import JobsSection  from '../sections/JobsSection';
import ContactSection from '../sections/ContactSection';
import { initMap } from '../utils';

const IndexPage = () => {
  useEffect(() => {
    initMap();
  }, []);
  return (
    <>
      <HeaderSection />
      <AboutSection />
      <JobsSection />
      {/*<EventsSection />*/}
      <ContactSection />
      <button type="button" className="scroll-top-arrow" style={{ cursor: 'pointer' }}><i className="fa fa-angle-up" /></button>
      <div id="aimated-cursor">
        <div id="cursor">
          <div id="cursor-loader" />
        </div>
      </div>
    </>
  );
};

const customProps = {
  localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(IndexPage);
